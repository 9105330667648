var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"options cias pd-20"},[_c('a-page-header',{staticClass:"mb-10",staticStyle:{"padding":"0"},attrs:{"sub-title":""},on:{"back":() => _vm.$router.go(-1)}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('h1',[_vm._v(_vm._s(_vm.cardMachine.id)+" - Editar PDQ")])]),_vm._v(" "),_c('template',{slot:"extra"})],2),_vm._v(" "),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":[20, 20]}},[_c('a-col',{staticClass:"box box-contractor",attrs:{"span":8}},[_c('a-row',{staticClass:"actions mt-20",staticStyle:{"min-height":"570px"},attrs:{"gutter":20}},[_c('h2',{staticClass:"title mb-20"},[_vm._v("DADOS DA PDQ")]),_vm._v(" "),_c('a-row',{staticStyle:{"padding":"0 10px"},attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Nome da máquina ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `name`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `name`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nome da máquina"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Número de série ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `serial_number`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `serial_number`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":" Número de série"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',[_c('span',{staticClass:"travel-input travel-multiselector"},[_c('label',{class:'filled'},[_vm._v("Tipo")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `type`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]),expression:"[\n                      `type`,\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Obrigatório',\n                          },\n                        ],\n                      },\n                    ]"}],staticClass:"travel-input",staticStyle:{"width":"100%","height":"30px"},attrs:{"placeholder":"Tipo","mode":"multiple"}},[_c('a-select-option',{attrs:{"value":"PDQ"}},[_vm._v(" PDQ ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"Link de Pagamento"}},[_vm._v("\n                      Link de Pagamento\n                    ")])],1)],1)])],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Empresa ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `company_id`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `company_id`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione uma empresa","optionFilterProp":"txt","show-search":""},on:{"change":_vm.getCompanyBranchesOnChange}},_vm._l((_vm.companiesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n                    "+_vm._s(item.id)+" -\n                    "+_vm._s(item.trading_name)+"\n                  ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Filial ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `company_branch_id`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `company_branch_id`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione uma filial","optionFilterProp":"txt","disabled":_vm.companyBranchesList.length == 0,"show-search":""}},_vm._l((_vm.companyBranchesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n                    "+_vm._s(item.id)+" - "+_vm._s(item.name)+"\n                  ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Banco ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `bank_id`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `bank_id`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Busque um banco","optionFilterProp":"txt","show-search":""}},_vm._l((_vm.companyBanksList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n                    "+_vm._s(item.id)+" - "+_vm._s(item.company_name)+" ("+_vm._s(item.account)+")\n                  ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Adquirente ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `acquirer_id`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `acquirer_id`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Busque um adquirente","optionFilterProp":"txt","show-search":""}},_vm._l((_vm.acquirersList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n                    "+_vm._s(item.id)+" - "+_vm._s(item.name)+"\n                  ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Status ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `status`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `status`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Status","optionFilterProp":"txt"}},[_c('a-select-option',{attrs:{"value":"Ativo"}},[_vm._v(" Ativo ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"Desativado"}},[_vm._v("\n                    Desativado\n                  ")])],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("\n                  Status para link de pagamento\n                ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `payment_link_status`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `payment_link_status`,\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Status","optionFilterProp":"txt"}},[_c('a-select-option',{attrs:{"value":"Ativo"}},[_vm._v(" Ativo ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"Desativado"}},[_vm._v("\n                    Desativado\n                  ")])],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Chave integração da máquina ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `machine_key`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `machine_key`,\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Chave integração da máquina"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{staticClass:"box box-contractor",attrs:{"span":16}},[_c('a-row',{staticClass:"actions mt-20",staticStyle:{"min-height":"570px"},attrs:{"gutter":20}},[_c('a-row',{staticClass:"title-box mb-20",attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_c('h3',[_vm._v("TAXAS")])]),_vm._v(" "),_c('a-col')],1),_vm._v(" "),_c('div',{staticClass:"travel-table last-itens",staticStyle:{"padding":"15px 10px"}},[_c('a-row',[_c('a-col',{staticClass:"a-center mb-30",attrs:{"span":8}},[_c('div',{staticStyle:{"font-size":"12px !important","font-weight":"600","color":"#666","margin-bottom":"4px"}},[_vm._v("\n                  Taxas da máquina\n                ")]),_vm._v(" "),_c('a-row',{staticClass:"mt-10 mb-10",staticStyle:{"font-size":"10px","font-weight":"600","letter-spacing":"-0.5px"}},[_c('a-col',{attrs:{"span":5}},[_vm._v(" Parcela ")]),_vm._v(" "),_c('a-col',{attrs:{"span":15}},[_vm._v(" Porcentagem (%) ")]),_vm._v(" "),_c('a-col',{attrs:{"span":4}})],1),_vm._v(" "),_vm._l((_vm.taxesRange),function(tax,index){return _c('a-row',{key:index,staticClass:"mb-10"},[_c('a-col',{attrs:{"span":5}},[_c('font',{staticStyle:{"position":"relative","top":"11px","font-weight":"600"}},[_vm._v("\n                      "+_vm._s(tax.id)+"\n                    ")])],1),_vm._v(" "),_c('a-col',{attrs:{"span":15}},[_c('a-form-item',{staticClass:"mb-0"},[_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                          currency: {
                            prefix: '',
                            suffix: '%',
                          },
                          distractionFree: false,
                          valueAsInteger: false,
                          precision: 2,
                          autoDecimalMode: false,
                          allowNegative: false,
                        }),expression:"{\n                          currency: {\n                            prefix: '',\n                            suffix: '%',\n                          },\n                          distractionFree: false,\n                          valueAsInteger: false,\n                          precision: 2,\n                          autoDecimalMode: false,\n                          allowNegative: false,\n                        }"},{name:"decorator",rawName:"v-decorator",value:([
                          `tax_range_${tax.id}`,
                          {
                            initialValue: tax.value,
                          },
                        ]),expression:"[\n                          `tax_range_${tax.id}`,\n                          {\n                            initialValue: tax.value,\n                          },\n                        ]"}],staticClass:"travel-input",on:{"change":function($event){return _vm.updateTaxValue(tax.id, index)}}})],1)],1),_vm._v(" "),_c('a-col',{staticClass:"a-center",attrs:{"span":4}},[(
                        tax.id === _vm.taxesRange.length && _vm.taxesRange.length > 0
                      )?_c('a-button',{staticClass:"mt-5",attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeTaxRange(tax.id)}}}):_vm._e()],1)],1)}),_vm._v(" "),_c('a-button',{staticClass:"mt-5",staticStyle:{"font-size":"12px"},attrs:{"disabled":_vm.taxesRange.length < 12 ? false : true,"size":"small"},on:{"click":function($event){return _vm.addTaxRange(_vm.taxesRange.length)}}},[_vm._v("Adicionar taxa")]),_vm._v(" "),_c('a-form-item',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `taxes_range`,
                      {
                        initialValue: JSON.stringify(_vm.taxesRange),
                      },
                    ]),expression:"[\n                      `taxes_range`,\n                      {\n                        initialValue: JSON.stringify(taxesRange),\n                      },\n                    ]"}]})],1)],2),_vm._v(" "),_c('a-col',{staticClass:"a-center mb-30",attrs:{"span":8}},[_c('div',{staticStyle:{"font-size":"12px !important","font-weight":"600","color":"#666","margin-bottom":"4px"}},[_vm._v("\n                  Taxas da máquina (Antecipação)\n                ")]),_vm._v(" "),_c('a-row',{staticClass:"mt-10 mb-10",staticStyle:{"font-size":"10px","font-weight":"600","letter-spacing":"-0.5px"}},[_c('a-col',{attrs:{"span":5}},[_vm._v(" Parcela ")]),_vm._v(" "),_c('a-col',{attrs:{"span":15}},[_vm._v(" Porcentagem (%) ")]),_vm._v(" "),_c('a-col',{attrs:{"span":4}})],1),_vm._v(" "),_vm._l((_vm.advanceFeeRange),function(advanceFee,index){return _c('a-row',{key:index,staticClass:"mb-10"},[_c('a-col',{attrs:{"span":5}},[_c('font',{staticStyle:{"position":"relative","top":"11px","font-weight":"600"}},[_vm._v("\n                      "+_vm._s(advanceFee.id)+"\n                    ")])],1),_vm._v(" "),_c('a-col',{attrs:{"span":15}},[_c('a-form-item',{staticClass:"mb-0"},[_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                          currency: {
                            prefix: '',
                            suffix: '%',
                          },
                          distractionFree: false,
                          valueAsInteger: false,
                          precision: 2,
                          autoDecimalMode: false,
                          allowNegative: false,
                        }),expression:"{\n                          currency: {\n                            prefix: '',\n                            suffix: '%',\n                          },\n                          distractionFree: false,\n                          valueAsInteger: false,\n                          precision: 2,\n                          autoDecimalMode: false,\n                          allowNegative: false,\n                        }"},{name:"decorator",rawName:"v-decorator",value:([
                          `advance_fee_${advanceFee.id}`,
                          {
                            initialValue: advanceFee.value,
                          },
                        ]),expression:"[\n                          `advance_fee_${advanceFee.id}`,\n                          {\n                            initialValue: advanceFee.value,\n                          },\n                        ]"}],staticClass:"travel-input",on:{"change":function($event){return _vm.updateAdvanceFeeValue(advanceFee.id, index)}}})],1)],1),_vm._v(" "),_c('a-col',{staticClass:"a-center",attrs:{"span":4}},[(
                        advanceFee.id === _vm.advanceFeeRange.length &&
                        _vm.advanceFeeRange.length > 0
                      )?_c('a-button',{staticClass:"mt-5",attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeAdvanceFee(advanceFee.id)}}}):_vm._e()],1)],1)}),_vm._v(" "),_c('a-button',{staticClass:"mt-5",staticStyle:{"font-size":"12px"},attrs:{"disabled":_vm.advanceFeeRange.length < 12 ? false : true,"size":"small"},on:{"click":function($event){return _vm.addAdvanceFee(_vm.advanceFeeRange.length)}}},[_vm._v("Adicionar taxa")]),_vm._v(" "),_c('a-form-item',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      `advance_fee`,
                      {
                        initialValue: JSON.stringify(_vm.advanceFeeRange),
                      },
                    ]),expression:"[\n                      `advance_fee`,\n                      {\n                        initialValue: JSON.stringify(advanceFeeRange),\n                      },\n                    ]"}]})],1)],2)],1)],1),_vm._v(" "),_c('div',{staticStyle:{"float":"left: width: 100%","clear":"both"}})],1)],1)],1),_vm._v(" "),_c('a-button',{staticStyle:{"float":"right"},attrs:{"size":"large","type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("\n      SALVAR ALTERAÇÕES\n    ")])],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }